import React from 'react';
import SunIcon from './Icons/SunIcon';
import MoonIcon from './Icons/MoonIcon';
import defaultAvatar from '../../assets/avatar.png';
import '../../css/skeleton.css';
import '../../css/components.css';
import { ThemeType } from '../../ts/types';
import BotLinkApp from '../../../../BotLinkSection';
import { TokenData } from '../../ts/types';
import '../../css/header.css'; // Assuming the CSS above is saved in this file

function turnNumberToWords(num: string | number): string {
  const suffixes = ["", "K", "M", "B", "T"];
  let newValue = num;
  if (typeof newValue === 'string') {
      newValue = parseFloat(newValue); // Using parseFloat to handle decimal values correctly
  }
  
  if (isNaN(newValue)) {
      return 'Not a number';
  }
  
  const tier = Math.floor(Math.log10(Math.abs(newValue)) / 3);
  
  if (tier === 0) {
      return newValue.toString();
  }
  
  const suffix = suffixes[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = newValue / scale;
  
  // Adjust the number of decimals based on the tier
  let decimals;
  switch (tier) {
      case 1: // Thousands
          decimals = 0; // No decimals for thousands
          break;
      case 2: // Millions
          decimals = newValue >= 10e6 ? 0 : 3; // No decimals if 10M and above, 3 decimals if below
          break;
      case 3: // Billions
      case 4: // Trillions
          decimals = 1; // One decimal place for billions and trillions
          break;
      default:
          decimals = 0; // Fallback
          break;
  }
  
  return scaled.toFixed(decimals) + suffix;
}
function Header({ avatar, id,tokenData }: { avatar?: string; id: string , tokenData: TokenData}): JSX.Element {
  const dataTheme = document.body.getAttribute('data-theme');
  const [theme, setTheme] = React.useState<ThemeType>((): ThemeType => (dataTheme === 'light' ? 'light' : 'dark'));

  const isMobile = window.innerWidth < 768;
  
  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  const handleSwitchTheme = () => {
    setTheme(isDark ? 'light' : 'dark');
  };

  const isDark = theme === 'dark';

  return (    <div>
    <div className="Header">
      <div className="Header__inner">
        <img 
          src={tokenData.image ?? defaultAvatar} 
          alt="avatar"
          className="Header__avatar"
        />
        <h2 className="Header__title">
          {tokenData.name}
        </h2>
        <span  className="BotLinkApp__button">
        <BotLinkApp id={id} />
        </span>
      </div>

      <div className="Info">
        <div className="Info__item">
          <h2>SUPPLY: <b>{turnNumberToWords(tokenData.supply)}</b></h2>
        </div>
        <div className="Info__item">
          <h2>MARKET CAP: <b>{turnNumberToWords(tokenData.marketCap)}</b></h2>
        </div>
      </div>

      {!isMobile ? (<button className="switch-theme-button" onClick={handleSwitchTheme}>
        {isDark ? <SunIcon color="white" /> : <MoonIcon />}
      </button>):null}
    </div>
    <div style={{marginLeft: "200px" }} >
    {!isMobile ? tokenData.description : null}
      </div>
    </div>
  );
}

export default Header;
