export const CHANNEL_TYPES = {
  DRIBBLE: 'DRIBBLE',
  GITHUB: 'GITHUB',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  TELEGRAM: 'TELEGRAM',
  TWITTER: 'TWITTER',
  WEBSITE: 'WEBSITE',
  YOUTUBE: 'YOUTUBE',
  OTHER: 'OTHER',
  BONK: 'BONK',
  DEXSCREENER: 'DEXSCREENER',
  JUPITER: 'JUPITER',
  RUGCHECK: 'RUGCHECK',
  SOLANAFM: 'SOLANAFM',
  SOLSCAN: 'SOLSCAN',
  
};
