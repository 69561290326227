import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./css/app.css"; // Ensure this contains the CSS you need
import GreenLogo from "./Linkees/src/components/components/Icons/GreenLogo";
import Search from './search'
import { ThemeType } from "linkees/ts";
import SunIcon from "./Linkees/src/components/components/Icons/SunIcon";
import MoonIcon from "./Linkees/src/components/components/Icons/MoonIcon";

function HomePage() {
  const [address, setAddress] = useState("");
  const dataTheme = document.body.getAttribute('data-theme');
  const [theme, setTheme] = React.useState<ThemeType>((): ThemeType => (dataTheme === 'light' ? 'light' : 'dark'));

  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  const handleSwitchTheme = () => {
    setTheme(isDark ? 'light' : 'dark');
  };

  const isDark = theme === 'dark';
  
  const navigate = useNavigate();

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAddress(e.target.value);
  };

  const determineBlockchain = (address: string) => {
    // Insert logic here to determine blockchain based on the address format
    if (/^0x[a-fA-F0-9]{40}$/.test(address)) {
      return "eth";
    } else if (/^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address)) {
      return "address";
    } // Add more conditions for BASE, BSC, and AVAX

    return null;
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const blockchain = determineBlockchain(address);
    if (blockchain) {
      navigate(`/${blockchain}/${address}`); // use navigate function here
    } else {
      alert("Invalid address. Please enter a valid blockchain address.");
    }
  };

  
  return (
    <div className='search-page'>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: "70vw",transform: "scale(0.7)"  }}>
          <button className="switch-theme-button" onClick={handleSwitchTheme}>
        {isDark ? <SunIcon color="white" /> : <MoonIcon />}
      </button>
      </div>

      <div className='Footer container' style={{ transform: "scale(0.45)" }}>
        <GreenLogo />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          value={address}
          onChange={handleChange}
          placeholder='Enter blockchain address'
          className='search-bar'
        />
        <button type='submit' className='search-button'>
          Search
        </button>
      </form>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/address/:id' element={<Search />} />
        {/* Add additional routes for other components if needed */}
      </Routes>
    </Router>
  );
}

export default App;

