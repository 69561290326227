// import LinkApp from "./LinkSection";
import Linkees, { CHANNEL_TYPES } from "./Linkees/src/index";
import React, { ReactNode, useEffect, useState } from "react";

import "./css/app.css"; // Ensure this contains the CSS you need

import "./css/normalize.css";

import { TokenData } from "./Linkees/src/ts";


const url = window.location.href;
const id = url.split("/").pop()?.toString() ?? "0";


// Ethereum Address Pattern (0x followed by 40 hex characters)
const ETH_ADDRESS_PATTERN = /^0x[a-fA-F0-9]{40}$/;
// Solana Address Pattern (44 Base58 characters)
const SOL_ADDRESS_PATTERN = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;

// async function fetchTokenData(address) {
//   if (ETH_ADDRESS_PATTERN.test(address)) {
//     return await getEthereumTokenName(address);
//   } else if (SOL_ADDRESS_PATTERN.test(address)) {
//     return await fetchSolTokenName(address);
//   }
//   return address;
// }

// async function getEthereumTokenName(address) {
//   const apiKey = process.env.REACT_APP_ETH_API_KEY;
//   const url = `https://api.etherscan.io/api?module=account&action=tokentx&address=${address}&page=1&offset=1&startblock=0&endblock=99999999&sort=asc&apikey=${apiKey}`;
//   try {
//     const response = await fetch(url);
//     const data = await response.json();
//     return data.status === "1" && data.result.length > 0
//       ? data.result[0].tokenName
//       : address;
//     console.log("data eth fetch", data);
//   } catch (error) {
//     console.error("Error fetching Ethereum token name:", error);
//     return address; // Return address if the fetch fails
//   }
// }

async function fetchSolTokenName(tokenId: string): Promise<TokenData> {
  const blankTokenData = {
    supply: 0,
    mintAuthorityRevoked: false,
    name: "Could Not Find Token Name",
    image: "",
    description: "",
    price: 0,
    marketCap: 0,
  };


  const apiKey = process.env.REACT_APP_SOL_API_KEY;
  const url = `https://mainnet.helius-rpc.com/?api-key=${apiKey}`;
  const body = JSON.stringify({
    jsonrpc: "2.0",
    id: "1",
    method: "getAsset",
    params: {
      id: tokenId,
    },
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const data = await response.json();


    if (data.result && data.result.content) {
      const tokenPrice = data.result.token_info.price_info.price_per_token; // price per token
      const decimal = data.result.token_info.decimals;
      const supplyWithoutDecimal = data.result.token_info.supply;
      const supply = supplyWithoutDecimal / Math.pow(10, decimal);
      const marketCap = tokenPrice * supply;
      const image = data.result.content.links.image;
      const description = data.result.content.metadata.description;
      const tokenName = data.result.content.metadata.name;
      console.log("name", tokenName);
      const tokenData = {
        mintAuthorityRevoked: false, // not working
        name: tokenName,
        description: description,
        supply: supply,
        price: tokenPrice,
        image: image,
        marketCap: marketCap, // Assuming we want the market cap to two decimal places
      };
      console.log("token data fetch comp: ", tokenData);

      console.log("token data fetch comp: ", tokenData);
      return tokenData;
    } else {
      return blankTokenData;
    }
  } catch (error) {
    console.error("Error fetching token name:", error);
    return blankTokenData;
  }
}



function Index(): JSX.Element {
const [tokenData, setTokenData] = useState<TokenData | null>(null);
const [id, setId] = useState<string>("0");



useEffect(() => {
  // Effect to run only once to set the initial 'id'
  const url = window.location.href;
  const initialId = url.split("/").pop()?.toString() ?? "0";
  setId(initialId);
}, []);


useEffect(() => {
  // Async function to fetch token data
  const fetchTokenData = async (tokenId: string) => {
    try {
      const data = await fetchSolTokenName(tokenId);
      setTokenData(data);
    } catch (error) {
      console.error("Error fetching token data:", error);
      // Here you might want to set an error state and display an error message
    }
  };

  if (id !== "0") {
    // Only call the function if 'id' is not "0"
    fetchTokenData(id);
  }
}, [id]); // Effect to run when 'id' changes


const items = [
  {
    // image: bonk,
    link: `https://t.me/bonkbot_bot?start=ref_7bcd8_ca_${id}`,
    subtitle: "Trade Fast With Bonk Bot 🚀",
    title: "Bonk",
    type: CHANNEL_TYPES.BONK,
  },
  {
    // image: Dexscreener,
    link: `https://dexscreener.com/solana/${id}`,
    subtitle: "Chart it out! 📈",
    title: "Dexscreener",
    type: CHANNEL_TYPES.DEXSCREENER,
  },
  {
    // image: Solscan,
    link: `https://solscan.io/token/${id}`,
    subtitle: "Dive in data 🔍",
    title: "Solscan",
    type: CHANNEL_TYPES.SOLSCAN,
  },

  {
    // image: Solanafm,
    link: `https://solana.fm/address/${id}`,
    subtitle: "Blockchain explorer 🌐",
    title: "Solana.fm",
    type: CHANNEL_TYPES.SOLANAFM,
  },
  {
    // image: Rug,
    link: `https://rugcheck.xyz/tokens/${id}`,
    subtitle: "Safety first! 🔐",
    title: "Rugcheck",
    type: CHANNEL_TYPES.RUGCHECK,
  },
  {
    // image: Jupiter,
    link: `https://jup.ag/swap/SOL-${id}`,
    subtitle: "Trade away! 🔄",
    title: "Jupiter",
    type: CHANNEL_TYPES.JUPITER,
  },
];


  return (
    <div>
      {tokenData !== null && tokenData?.supply !== 0 && id !== "0" ? (
        <Linkees key={id} id={id} cardItems={items} tokenData={tokenData} />
      ) : (
        <div style={{ backgroundColor: "black", color: "white", fontSize: "large", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          Loading...
        </div>
      )}
    </div>
  );
}
export default Index;
