import "./css/botlinkapp.css"; // Updated import statement for the correct CSS file

import bonk from './img/bonk-small.png';
import trojan from './img/trojan.png';
import React, { ReactNode } from "react";

// Define types for the props
interface LinkInfo {
  title: string;
  subtitle: string;
  type: string;
  link: string;
  image: any;
}

type LinkCardProps = LinkInfo

interface LinkSectionProps {
  links: LinkInfo[];
}

interface LinkAppProps {
  id: string;
}

// Example usage:
// const lastSegment = extractLastPartOfPath(window.location.href);

// Utility function to build Telegram bot links
function getTelegramLink(botName: string, command: string, id: string): string {
  return `https://t.me/${botName}?start=${command}_${id}`;
}

// Generate links function with TypeScript typing
function generateLinks(id: string): LinkInfo[] {
  const CHANNEL_TYPES = {
    BONK: 'BONK',
    TROJAN: 'TROJAN'
  };
  return [
    {
      image: bonk,
      link: getTelegramLink("bonkbot_bot", "ref_7bcd8_ca", id),
      subtitle: "",
      title: "Bonk",
      type: CHANNEL_TYPES.BONK
    },
    {
      image: trojan,
      link: getTelegramLink("solana_trojanbot", "r-nobodynotices", id),
      subtitle: "",
      title: "Trojan",
      type: CHANNEL_TYPES.TROJAN
    }
  ];
}

const LinkCard: React.FC<LinkCardProps> = ({ title, subtitle, link, image }) => (
  <a href={link} className="button" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div >
      <img src={image} alt={title} className="link-card-icon" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
      <span style={{ marginLeft: "2px", marginBottom: "30px" }}>{title}</span>
    </div>
  </a>
);

const LinkSection: React.FC<LinkSectionProps> = ({ links }) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    {links.map((link, index) => (
      <div key={index} style={{ marginRight: '2px' }}>
        <LinkCard {...link} />
      </div>
    ))}
  </div>
);

const BotLinkApp: React.FC<LinkAppProps> = ({ id }) => {
  const links = generateLinks(id);
  return (

      <div className="button-container">

      <LinkSection links={links} />
</div>

  );
};

export default BotLinkApp;
