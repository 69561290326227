import React from 'react';

import '../../css/skeleton.css';
import '../../css/components.css';
import GreenLogo from './Icons/GreenLogo';

function Footer(): JSX.Element {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="Footer container" style={{ transform: 'scale(0.25)' }}>
      <GreenLogo />
    </div>
  );
}

export default Footer;
