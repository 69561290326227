import React from "react";
import ReactDOM from "react-dom/client";
// import LinkApp from "./LinkSection";
import Linkees, { CHANNEL_TYPES } from "./Linkees/src/index";

import "./css/normalize.css";

import App from "./app";


const rootElement = document.getElementById("root");

if (rootElement) {


    ReactDOM.createRoot(rootElement)?.render(
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    );
  }

